@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;

  &.full-movie {
    background: url("./images/poster.png") no-repeat center center;
    background-size: cover;

    section {
      @apply z-10 absolute flex flex-col items-center w-full gap-14 h-[80vh] justify-evenly;

      img {
        @apply block;
        max-height: 120px;
      }

      .big-title {
        @apply text-white uppercase font-bold tracking-[1rem];
        font-size: clamp(1.875rem, -3.2813rem + 16.5vw, 6rem);
      }
    }
  }
}

.iframe-wrapper {
  @apply fixed top-0 left-0 w-full h-full pointer-events-none overflow-hidden;

  &.about {
    @apply -left-1/2 hidden md:block invert brightness-150;
  }

  &:after {
    @apply absolute left-0 w-full h-full content-[""];
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.iframe-wrapper iframe {
  @apply w-full absolute top-1/2 left-1/2 min-h-screen -translate-x-1/2  -translate-y-1/2;
  background: url("./images/poster.png") no-repeat center center;
  background-size: cover;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
}

.video-section {
  @apply md:w-1/2 w-full flex justify-center items-center text-center min-h-60 lg:min-h-0 relative;
  background: url("./images/poster.png") no-repeat center center;

  &:after {
    @apply absolute left-0 w-full h-full content-[""];
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.beauty-in-rot {
  @apply text-white uppercase z-20 px-10 tracking-wider;
  font-size: clamp(1.875rem, 0.4688rem + 4.5vw, 3rem);
}

#interior-page {
  @apply p-8 text-white bg-black;

  &.mobile-nav-active {
    @apply bg-[#fafafa];
  }
}

.inner-page-headline {
  @apply text-lg md:text-4xl md:text-white font-semibold;
}

.inner-page-sub-headline {
  @apply text-xs md:text-xl md:text-[#aaa] font-normal;
}

.inner-page-content {
  @apply gap-10 text-center items-center justify-center mt-16;
}

.hamburger {
  @apply flex flex-col justify-center relative w-12 z-10 md:hidden;

  svg {
    @apply cursor-pointer absolute;

    &#icon-burger {
      @apply right-1;
    }

    &#icon-close {
      @apply right-3;
    }
  }
}

.nav-wrapper {
  @apply fixed h-screen items-center justify-center w-full left-1/2 -translate-x-1/2;

  ul {
    @apply flex flex-col gap-4 uppercase text-center font-medium text-[#333];
    font-size: clamp(1.875rem, 0.4688rem + 4.5vw, 3rem);
  }
}

#mail-form {
  @apply text-black font-normal flex flex-col gap-3;

  label {
    @apply text-white text-left;
  }

  input {
    @apply p-3;
  }

  button {
    @apply text-fermented-yellow border-fermented-yellow border-2 self-center p-4 px-12 mt-4 rounded-3xl uppercase font-black tracking-[1rem] transition-all;

    &:hover {
      @apply bg-fermented-yellow text-black;
    }
  }
}
