*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.mt-20 {
  margin-top: 5rem;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.self-center {
  align-self: center;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-\[\#333\] {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}

.text-\[\#555\] {
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity));
}

.text-\[\#aaa\] {
  --tw-text-opacity: 1;
  color: rgb(170 170 170 / var(--tw-text-opacity));
}

.text-\[\#c7ceff\] {
  --tw-text-opacity: 1;
  color: rgb(199 206 255 / var(--tw-text-opacity));
}

.text-fermented-gray {
  color: #fff3;
}

.text-fermented-yellow {
  --tw-text-opacity: 1;
  color: rgb(242 255 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.decoration-slate-200 {
  text-decoration-color: #e2e8f0;
}

.underline-offset-8 {
  text-underline-offset: 8px;
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

html {
  height: 100%;
}

body {
  font-optical-sizing: auto;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 800;

  &.full-movie {
    background: url("poster.499227e9.png") center / cover no-repeat;

    & section {
      z-index: 10;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      gap: 3.5rem;
      width: 100%;
      height: 80vh;
      display: flex;
      position: absolute;
    }

    & section {
      & img {
        max-height: 120px;
        display: block;
      }

      & .big-title {
        text-transform: uppercase;
        letter-spacing: 1rem;
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
        font-size: clamp(1.875rem, 16.5vw - 3.2813rem, 6rem);
        font-weight: 700;
      }
    }
  }
}

.iframe-wrapper {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;

  &.about {
    --tw-brightness: brightness(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    --tw-invert: invert(100%);
    display: none;
    left: -50%;
  }

  @media (width >= 768px) {
    &.about {
      display: block;
    }
  }

  &:after {
    --tw-content: "";
    content: var(--tw-content);
    background-color: #0003;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
  }
}

.iframe-wrapper iframe {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background: url("poster.499227e9.png") center / cover no-repeat;
  width: 100%;
  min-width: 177.77vh;
  height: 56.25vw;
  min-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
}

.video-section {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 15rem;
  display: flex;
  position: relative;
}

@media (width >= 768px) {
  .video-section {
    width: 50%;
  }
}

@media (width >= 1024px) {
  .video-section {
    min-height: 0;
  }
}

.video-section {
  background: url("poster.499227e9.png") center no-repeat;

  &:after {
    --tw-content: "";
    content: var(--tw-content);
    background-color: #0000004d;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
  }
}

.beauty-in-rot {
  z-index: 20;
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  font-size: clamp(1.875rem, .4688rem + 4.5vw, 3rem);
}

#interior-page {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: 2rem;

  &.mobile-nav-active {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  }
}

.inner-page-headline {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  .inner-page-headline {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.inner-page-sub-headline {
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
}

@media (width >= 768px) {
  .inner-page-sub-headline {
    --tw-text-opacity: 1;
    color: rgb(170 170 170 / var(--tw-text-opacity));
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.inner-page-content {
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  margin-top: 4rem;
}

.hamburger {
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  width: 3rem;
  display: flex;
  position: relative;
}

@media (width >= 768px) {
  .hamburger {
    display: none;
  }
}

.hamburger {
  & svg {
    cursor: pointer;
    position: absolute;
  }

  & svg {
    &#icon-burger {
      right: .25rem;
    }

    &#icon-close {
      right: .75rem;
    }
  }
}

.nav-wrapper {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 50%;

  & ul {
    text-align: center;
    text-transform: uppercase;
    --tw-text-opacity: 1;
    color: rgb(51 51 51 / var(--tw-text-opacity));
    flex-direction: column;
    gap: 1rem;
    font-size: clamp(1.875rem, .4688rem + 4.5vw, 3rem);
    font-weight: 500;
    display: flex;
  }
}

#mail-form {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  flex-direction: column;
  gap: .75rem;
  font-weight: 400;
  display: flex;

  & label {
    text-align: left;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  & input {
    padding: .75rem;
  }

  & button {
    --tw-border-opacity: 1;
    border-width: 2px;
    border-color: rgb(242 255 0 / var(--tw-border-opacity));
    text-transform: uppercase;
    letter-spacing: 1rem;
    --tw-text-opacity: 1;
    color: rgb(242 255 0 / var(--tw-text-opacity));
    border-radius: 1.5rem;
    align-self: center;
    margin-top: 1rem;
    padding: 1rem 3rem;
    font-weight: 900;
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  & button {
    &:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(242 255 0 / var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgb(0 0 0 / var(--tw-text-opacity));
    }
  }
}

.hover\:text-fermented-yellow:hover {
  --tw-text-opacity: 1;
  color: rgb(242 255 0 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:decoration-slate-500:hover {
  text-decoration-color: #64748b;
}

.hover\:opacity-50:hover {
  opacity: .5;
}

@media (width >= 640px) {
  .sm\:flex-row {
    flex-direction: row;
  }
}

@media (width >= 768px) {
  .md\:flex {
    display: flex;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:gap-8 {
    gap: 2rem;
  }
}

@media (width >= 1024px) {
  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
/*# sourceMappingURL=index.00a562c7.css.map */
